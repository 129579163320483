import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { map } from 'lodash';
import './style.css';
import { useIntl } from 'gatsby-plugin-intl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import ImageBlock from '../ImageBlock/ImageBlock';
import icon from '../../assets/icons/map.svg';
import BlockLayout from '../../layaouts/BlockLayout/BlockLayout';
import albero from '../../assets/icons/albero_white.svg';
import Carusel from '../Forms/Carusel/Carusel';
import Button from '../Forms/Button/Button';
import arrR from '../../assets/icons/arrR.svg';
import MetaTags from '../Header/MetaTags';
import AziendeCard from './AziendeCard/AziendeCard';
import Promotori from '../Promotori/Promotori';

const renderContactSection = (section, info, infoLabel) => {
  if (!section || !info || !infoLabel) return;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const breakpoints = useBreakpoint();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: !breakpoints.md ? 'center' : 'flex-start',
        justifyContent: 'flex-end',
        flexDirection: !breakpoints.md ? 'row' : 'column',
        marginBottom: 10,
      }}
    >
      <div
        style={{
          marginRight: 15,
          fontWeight: 'bold',
          marginTop: !breakpoints.md ? 0 : 5,
          marginBottom: !breakpoints.md ? 0 : 5,
        }}
      >
        {section}
      </div>
      <div
        style={{
          fontWeight: 300,
          fontStyle: 'normal',
          backgroundColor: 'white',
          padding: 10,
          fontSize: 15,
          width: !breakpoints.md ? 340 : 274,
          marginRight: 20,
          marginTop: !breakpoints.md ? 0 : 5,
          marginBottom: !breakpoints.md ? 0 : 5,
        }}
      >
        {info}
      </div>
      <a
        href={`mailto:${info}`}
        style={{ textDecoration: 'none' }}
      >
        <div
          style={{
            backgroundColor: '#003F6E',
            color: 'white',
            padding: '10px 4px',
            fontSize: 15,
            width: 140,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginTop: !breakpoints.md ? 0 : 10,
            marginBottom: !breakpoints.md ? 0 : 10,
            borderRadius: 4,
            textAlign: 'center',
          }}
        >
          {infoLabel}
        </div>
      </a>
    </div>

  );
};

function Aziende({ data }) {
  const intl = useIntl();
  const breakpoints = useBreakpoint();
  const { yoast_head_json } = data[intl.locale];

  const [collapse, setCollapse] = useState(true);
  const btnLabel = collapse
    ? intl.formatMessage({ id: 'btn_vedi_di_piu' })
    : intl.formatMessage({ id: 'btn_vedi_di_meno' });

  const {
    foundationHero,
    descriptionParagraph,
    howToSupport,
    findFiscalBenefits,
    supportMethods,
    promotorClaimers,
  } = data[intl.locale].acf;

  const helpModes = supportMethods.support;
  const contactUsData = howToSupport.contactUs;
  const [selectedHelpMode, setSelectedHelpMode] = useState(
    helpModes[0].title.toUpperCase(),
  );

  const selectHelpMode = useCallback((help) => {
    // eslint-disable-next-line max-len
    setSelectedHelpMode(help);
  }, []);

  const helpTypes = useMemo(
    () => map(helpModes, (help) => {
      if (!help.title) return null;
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={
            help.title.toUpperCase() === selectedHelpMode.toUpperCase()
              ? 'azienda_singleHelpCategory_Selected'
              : 'azienda_singleHelpCategory'
          }
          style={{ marginRight: !breakpoints.md ? 20 : 0 }}
          data-selected-id={help.title}
          selected={selectedHelpMode === help.title}
          // data-donation-name={don.title}
          // eslint-disable-next-line react/jsx-props-no-multi-spaces
          onClick={() => selectHelpMode(help.title)}
          onKeyPress={(e) => e.preventDefault()}
        >
          {help.title}
        </div>
      );
    }),
    [breakpoints.md, helpModes, selectHelpMode, selectedHelpMode],
  );

  const helpTypesBody = useMemo(
    () => map(helpModes, (help) => {
      if (!help.title) return null;
      return (
        <div
          className="aziende_help_Body"
          style={{
            display:
              help.title.toUpperCase() === selectedHelpMode.toUpperCase()
                ? 'flex'
                : 'none',
            flexDirection: 'column',
            margin: '0 auto',
            marginBottom: 50,
            maxWidth: !breakpoints.md ? '100%' : 330,
          }}
        >
          <div className="aziende_help_Body__data_title">{help.title}</div>
          <div className="aziende_help_Body__data_desc">
            {help.contacts.map(i=>{
              return renderContactSection(i.fieldLabel, i.fieldValue, i.filedButtonLabel);
            })}
          </div>
        </div>
      );
    }),
    [breakpoints.md, contactUsData, helpModes, selectedHelpMode],
  );

  useEffect(() => {
    const mylist = document.getElementById('aziende');
    const divs = mylist.children;
    const listitems = [];
    for (let i = 0; i < divs.length; i++) {
      listitems.push(divs.item(i));
    }

    listitems.sort((a, b) => {
      const compA = a.getAttribute('id');
      const compB = b.getAttribute('id');
      return compA < compB ? -1 : compA > compB ? 1 : 0;
    });
    for (let i = 0; i < listitems.length; i++) {
      mylist.appendChild(listitems[i]);
    }
  }, []);

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div id="aziende" className="aziende_main_container">
        {(foundationHero.imageBackgroundFoundationHero && foundationHero.title) && (
        <div id={foundationHero.positionFoundationHero} style={{ width: '100%' }}>
          <ImageBlock type="3" img={foundationHero.imageBackgroundFoundationHero}>
            <div
              style={{
                marginLeft: !breakpoints.md ? 130 : 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="aziende_title3">{foundationHero.title}</div>
              <h2 className="aziende_subtitle3">
                {foundationHero.subtitle && foundationHero.subtitle}
              </h2>
            </div>
          </ImageBlock>
        </div>
        )}

        {(descriptionParagraph.paragraphTitle && descriptionParagraph.contentVisibile) && (
        <div style={{ maxWidth: 693 }} id={descriptionParagraph.positionParagraph}>
          <div className="collapsableTextBox">
            <div className="titleBox">{descriptionParagraph.paragraphTitle}</div>
            {descriptionParagraph.contentVisibile && (
            <div className="collapsable">
              <div className="collapsableText">
                <span
                  dangerouslySetInnerHTML={{
                    __html: collapse
                      ? descriptionParagraph.contentVisibile
                      : descriptionParagraph.contentVisibile.concat(
                        descriptionParagraph.contentHidden,
                      ),
                  }}
                />
              </div>

              {descriptionParagraph.contentHidden && (
              <span style={{
                display: 'flex',
                flexDirection: 'row',
              }}
              >
                <Button
                  handleClick={() => {
                    setCollapse(!collapse);
                  }}
                  id="collapseBtn"
                  label={btnLabel}
                  style={{
                    borderRadius: 0,
                    textTransform: 'uppercase',
                    color: 'black',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  {collapse ? (
                    <img
                      alt="accordionIMG"
                      src={arrR}
                      style={{
                        marginLeft: 6,
                        width: '16px',
                        height: '16px',
                      }}
                    />
                  ) : (
                    <img
                      alt="accordionIMG"
                      src={arrR}
                      style={{
                        marginLeft: 6,
                        width: '16px',
                        height: '16px',
                        transform: 'rotate(-180deg)',
                      }}
                    />
                  )}
                </Button>
              </span>
              )}
            </div>
            )}
          </div>
        </div>
        )}

        {(howToSupport) && (
        <div id={howToSupport.positionSupport} className="aziende_body">
          <h2
            className="aziende_body__title"
            style={{ width: !breakpoints.xs ? '100%' : 330 }}
          >
            {intl.formatMessage({ id: 'aziende_come_sostenerci_label' })}
          </h2>
          <div className="aziende_helpModes_container">
            {helpTypes}
          </div>
          {helpTypesBody}
        </div>
        )}

        {(data[intl.locale].acf.promotorClaimers) && (
        <div id={data[intl.locale].acf.promotorClaimers.positionClaimers} style={{ width: '100%' }}>
          <BlockLayout
            style={{
              backgroundImage: `url(${albero})`,
              width: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: !breakpoints.md ? '80%' : '110%',
              backgroundPosition: !breakpoints.md ? '310% -100%' : '-450% 33%',
            }}
            icon={icon}
            isTitileH2
            title="Le Aziende Partner"
            bgColor="#f2f5f7"
            isTitleH3
          >
            <Promotori promoters={promotorClaimers} />
          </BlockLayout>
        </div>
        )}

        {(findFiscalBenefits.titleFiscalBenefits && findFiscalBenefits.backgroundImageFiscalBenefits) && (
        <div id={findFiscalBenefits.positionFiscalBenefits} style={{ width: '100%' }}>
          <ImageBlock
            isTitileH3
            type="2"
            img={findFiscalBenefits.backgroundImageFiscalBenefits}
            label={findFiscalBenefits.descriptionFiscalBenefits ? findFiscalBenefits.descriptionFiscalBenefits : ''}
            title={findFiscalBenefits.titleFiscalBenefits}
            btnlabel={findFiscalBenefits.buttonLabelFiscalBenefits ? findFiscalBenefits.buttonLabelFiscalBenefits : 'NaN'}
            goto="/benefici-fiscali"
            lightBlock={findFiscalBenefits.blockLight !== null}
          />
        </div>
        )}
      </div>
    </>
  );
}

export default Aziende;
